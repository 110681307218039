.main-fixed-header {
    position: fixed;
    z-index: 10;
    top: 52.1px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-left: calc(3rem - 10px);
    padding-right: 3rem;
    background-color: var(--bg-white);
    /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 -71px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); */
}

.main-lists {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    align-items: center;
    color: var(--tx-main-light);
}

.main-lists::-webkit-scrollbar {
    display: none;
}

.main-list {
    padding: 10px;
}

.main-list a {
    color: var(--tx-main-light);
}

.main-list.active {
    color: var(--tx-black);
    border-bottom: 2px solid var(--tx-black);
}

.main-list.active a {
    color: var(--tx-black);
    font-weight: bold;
}

.main-content {
    width: 100%;
    margin-top: 99.4px;
    position: relative;
}

/* sections */

.section-container[data-area="creator"] .hero-info {
    margin-top: 0;
}

.section-container[data-area="creator"] .hero-content {
    position: relative;
}

.v-survey {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #eee;
    position: relative;
}

.v-survey .r-float {
    width: 25px;
    height: 25px;
}

.dialog-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    font-family: 'Lato', sans-serif;
}

.dialog-wrapper button {
    background: transparent;
    border: none;
}

.dialog-text .title {
    font-size: 1.2rem;
}

.dialog-actions {
    margin-top: 1rem;
}

.dialog-actions button {
    background-color: var(--cl-primary);
    color: var(--tx-white);
    padding: .5rem 1rem;
    border-radius: 5px;
}

.dialog-cancel {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.dialog-cancel:hover {
    background-color: #eee;
}

.section-container .hero-info[data-area="creator-banner"] {
    flex: 50%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--tag-bg);
}

.section-container .hero-info[data-area="creator-info"] {
    flex: 50%;
    height: 100%;
    background-color: var(--bg-white);
}

.hero-creator-title {
    color: var(--tx-black);
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: -.5px;
    transition: all .5s;
    display: flex;
    align-items: center;
    z-index: 4;
}

.hero-creator-title span {
    line-height: normal;
    display: flex;
}

.hero-creator-title span img {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.hero-creator-bio {
    color: var(--tx-main-light);
    font-size: 1.1rem;
    max-width: 50ch;
    text-align: center;
}

.hero-creator-created {
    color: var(--tx-main-light);
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 10px;
}

.hero-float {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-float-img {
    width: 100px;
    height: 100px;
}

.hero-float-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid var(--bg-white);
}


.section-container.v-inject {
    margin: 20px 3rem 20px 3rem;
    border: 1px solid rgb(227, 231, 240);
    border-radius: 10px;
}

.section-container .section-title {
    font-size: 1.8rem;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    letter-spacing: -1px;
}

.section-container .section-title.md {
    font-size: 1.3rem;
    margin: 0 20px;
}

.section-row .section-col {
    padding: 0;
}

.section-row {
    padding: 0 calc(3rem - 10px);
    margin: 0;
    position: relative;
}

.section-listings {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.section-listings::-webkit-scrollbar {
    display: none;
}

/* banner */
.banner-wrapper {
    background-color: var(--cl-primary);
    border-radius: 1rem;
    padding: 2rem 3rem;
    color: var(--cl-white);
}

.banner-items {
    height: 500px;
    display: flex;
    align-items: center;
}

.banner-illustration {
    flex: 1;
    height: 100%;
    overflow: hidden;
}

.banner-illustration img {
    width: 85%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.banner-description {
    flex: 1;
}

.banner-title {
    color: var(--cl-white);
    font-size: 3rem;
    max-width: 20ch;
    font-family: 'Lato', sans-serif;
}

.banner-desc {
    margin-top: 2rem;
}

.mini-desc {
    font-size: 1.4rem;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-style: italic;
}

.mini-lists {
    width: 100%;
    max-width: 70ch;
    padding: 2rem;
    border-top: 1px solid var(--cl-white);
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.mini-item {
    font-size: 1.1rem;
    font-family: 'Lato', sans-serif;
}

.banner-action {
    margin-top: 2rem;
}

.banner-action a {
    background-color: var(--bg-white);
    color: var(--cl-primary);
    font-size: 1.2rem;
    padding: .8rem 1rem;
    border-radius: 6px;
}


/* card */
.card-box {
    padding: 10px;
    height: 315px;
    display: flex;
    flex-direction: column;
    transition: all .5s;
}

.card-box.resource {
    height: 320px;
}

.card-img-area {
    height: 180px;
    transition: all .5s;
}

.card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid #eee;
}

.card-desc-area {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.card-tag {
    text-transform: uppercase;
    margin-top: 5px;
    font-size: .8rem;
    font-weight: 500;
}

.card-tag a {
    color: var(--bg-black);
}

.card-title {
    font-size: 1.1rem;
    letter-spacing: -.8px;
    margin-top: 8px;
    font-weight: bold;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: var(--tx-black);
}

.card-title a {
    color: var(--tx-black);
}

.card-user,
.card-user-info {
    display: flex;
    flex-wrap: wrap;
}

.card-user {
    margin-top: 10px;
    flex: 1;
    align-items: flex-end;
}

.card-user img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
}

.card-user-info {
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-left: .3rem;
    color: var(--tx-main-light);
    padding-bottom: 5px;
}

.card-user-info a {
    color: var(--tx-main-light);
}

.card-name {
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
}

.card-date {
    font-size: .67rem;
    letter-spacing: -.5px;
    text-transform: uppercase;
    opacity: .9;
}

.card-badge{
    width: 15px;
    height: 15px;
    object-fit: cover;
}

/* mini card */
.mini-card {
    flex: 0 0 auto;
    padding: 1rem;
    border: 1px solid rgb(227, 231, 240);
    display: flex;
    width: 280px;
    height: 120px;
    border-radius: 10px;
    position: relative;
}

.mini-card+.mini-card {
    margin-left: 10px;
}

.mini-img-area {
    width: 40px;
    height: 40px;
}

.mini-img-area img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.mini-info-area {
    margin-left: 1rem;
    color: var(--tx-main-light);
    font-family: 'Lato', sans-serif;
}

.fx-mini {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: .9rem;
}

.pin-tr {
    position: absolute;
    top: 12px;
    right: 10px;
    font-size: .8rem;
    color: var(--tx-main-light);
}

.pin-tr a {
    color: var(--tx-black);
}

.pin-tr a:hover {
    text-decoration: underline;
}

.info-name {
    color: var(--tx-black);
    font-weight: bold;
    margin-bottom: 6px;
    max-width: 15ch;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.info-name a {
    color: var(--tx-black);
}

.info-name a:hover {
    text-decoration: underline;
}

.mult-info {
    margin-top: 1rem;
}

.mult-info .fx-mini {
    gap: .5rem;
}

.info-price {
    margin-top: 5px;
    gap: .5rem;
}

/* section v-bg-break */
section.v-skills {
    margin-top: 2rem;
    padding: 3rem 3rem;
}

.v-bg-break {
    width: 100%;
    background-color: var(--bg-black);
    color: var(--tx-white);
    font-family: 'Lato', sans-serif;
}

section.v-skills .section-title {
    padding-top: 0;
    padding-bottom: 3rem;
}

section.v-skills .section-row {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 15px;
    overflow-x: scroll;
}

section.v-skills .section-row::-webkit-scrollbar {
    display: none;
}

section.v-skills .section-row .section-col {
    flex: 0 0 auto;
    margin-left: 0;
}

.v-scroll+.v-scroll {
    padding-left: 20px;
}

.skill-card {
    width: 100%;
    height: 23rem;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid rgb(238, 238, 238, 30%);
}

.skill-banner-area {
    width: 100%;
    height: 12rem;
}

.skill-banner-area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.skill-info-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.skill-title {
    font-size: 1.05rem;
    letter-spacing: -.4px;
    margin-top: 8px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
    -webkit-box-orient: vertical;
    line-height: 24px;
    display: -webkit-box;
    opacity: .9;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: var(--tx-black);
}

.dark .skill-title a {
    color: var(--cl-white);
}

.skill-title a {
    color: var(--tx-black);
}

.skill-tag {
    background-color: var(--cl-secondary);
    color: var(--tx-white);
    width: fit-content;
    font-size: .8rem;
    padding: .2rem .4rem;
    border-radius: 5px;
}

.skill-tag a {
    color: var(--tx-white);
}

.skill-author-area {
    flex: 1;
    display: flex;
    align-items: flex-end;
    color: #757575;
}

.skill-author-area a {
    display: flex;
    align-items: center;
    color: var(--tx-main-light);
}

.dark span,
.dark .skill-author-area a {
    color: var(--cl-white);
    opacity: .9;
}

.skill-author-area img {
    /* margin-bottom: 5px; */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.skill-author-area .author-name {
    font-size: .9rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
}

.skill-author-area .author-name .name-area {
    display: flex;
    gap: 5px;
    font-weight: 300;
}

.skill-author-area .author-name .name-area .bd-area {
    font-weight: 400;
}

.skill-author-area .author-name .author-reviews {
    display: flex;
    align-items: center;
    gap: 7px;
}

.skill-author-area .rate-stars {
    display: flex;
    gap: 5px;
}

.skill-author-area .rate-stars .fa {
    display: flex;
    align-items: center;
}

.skill-author-area .skill-price {
    margin-bottom: 5px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    color: var(--tx-black);
    opacity: .8;
}

.dark .skill-price {
    color: var(--tx-white);
}

.main-footer {
    width: 100%;
    margin-top: 2rem;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgb(227, 231, 240)
}

.main-footer.id-footer {
    margin-top: 0;
    background-color: var(--tag-bg);
}

.footer-content {
    width: 100%;
}

.footer-row .footer-col {
    padding: 0;
}

.footer-row {
    margin-bottom: 0;
}

.foot-links,
.footer-copy,
.foot-links a {
    color: var(--tx-black);
}

.foot-links a {
    font-size: .9rem;
}

.footer-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.nav-socials {
    display: flex;
    align-items: center;
    gap: 2rem;
}

/* housing */
/* card */
.house-card-box {
    padding: 10px;
    height: 280px;
    display: flex;
    flex-direction: column;
    transition: all .5s;
}

.house-card-box.resource {
    height: 320px;
}

.house-card-img-area {
    height: 180px;
    transition: all .5s;
}

.house-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid #eee;
}

.house-card-desc-area {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end
}

.house-card-tag {
    text-transform: uppercase;
    margin-top: 5px;
    font-size: .8rem;
    font-weight: 500;
}

.house-card-title {
    font-size: 1.1rem;
    letter-spacing: -.1px;
    font-weight: bold;
    line-height: 24px;
    font-family: 'Lato', sans-serif;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.house-card-title a {
    color: var(--tx-black);
}

.house-card-review,
.house-card-location {
    display: flex;
    align-items: center;
    gap: 7px;
    font-family: 'Lato', sans-serif;
    margin-top: 5px;
}

.house-card-location a {
    color: var(--tx-black);
}

.house-card-review span,
.house-card-location span {
    line-height: normal;
}

.house-card-review .fa,
.house-card-location .fa {
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.house-card-review span:first-child {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--bg-black);
}

.house-card-review span:first-child .fa {
    color: var(--cl-star);
}

.house-card-review span:nth-child(2) {
    color: var(--tx-main-light);
    font-size: .9rem;
}

.house-card-location span:first-child {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--bg-black);
}

.house-card-location span:first-child .fa {
    color: var(--tx-main-light);
}

.house-card-location span:nth-child(2) {
    color: var(--tx-main-light);
    font-size: .9rem;
}

/* tag container */
.tag__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid #eee;
    border-radius: 7px;
    background-color: #eee;
    ;
    margin-bottom: 1.5rem;
}

.tag__container.dark {
    background-color: transparent;
    border: none;
}

.tag__banner__title {
    width: 100%;
    height: 130px;
    transition: height .3s ease-out;
    overflow: hidden;
    position: relative;
}

.overlayed {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.over__lay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000014;
}

.tag__wrapper .over__lay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .15);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.tag__container.dark .over__lay {
    background-color: rgba(0, 0, 0, .35);
}

.tag__wrapper .over__text {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tx-white);
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 900;
}

.tag__banner__title img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    filter: blur(1px);
    object-fit: cover;
}

.tag__info__actions {
    padding: 1rem;
}

.tag__info__actions .info {
    color: var(--tx-black);
    font-size: .9rem;
}


.tag__info__actions .post_count {
    font-size: .8rem;
    padding-top: 1rem;
    color: var(--tx-main-light);
}


/* resize */

@media (max-width: 480px) {

    .pd-lr {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .v-survey {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .dialog-wrapper {
        padding: 0;
    }

    .dialog-info {
        width: 100%;
        text-align: center;
    }

    .dialog-actions button {
        width: 100%;
    }

    .main-fixed-header {
        padding-left: 0;
    }

    .content-box {
        padding-left: 0;
        padding-right: 0;
    }

    .nav-search {
        margin-left: 1rem;
        min-width: 230px;
    }

    section.v-skills {
        padding: 3rem 1rem;
    }

    .section-container .section-title {
        font-size: 1.5rem;
    }

    .section-container .section-title.md {
        padding-bottom: 1rem;
    }

    .section-container.v-inject {
        margin-top: 0;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .section-card {
        padding-bottom: 2rem !important;
    }

    .section-card+.section-card {
        padding-top: 2rem;
        border-top: 1px solid rgb(227, 231, 240);
    }

    .dark.section-card+.dark.section-card {
        padding-top: 0rem;
        border-top: none;
    }

    .hero-content .hero-title {
        font-size: 2.2rem;
    }

    .card-box {
        flex-direction: row;
        height: unset;
        padding: 0;
    }

    .skill-card {
        padding: 0;
    }

    .dark .skill-card {
        padding: 10px;
    }

    .card-tag {
        margin-top: 0;
    }

    .card-box.resource {
        height: unset;
    }

    .card-title {
        font-size: 1rem;
        margin-top: 5px;
        -webkit-line-clamp: 2;
        line-height: 20px;
    }

    .card-box .card-img-area {
        width: 90px;
        height: 90px;
    }

    .card-desc-area {
        margin-left: 1rem;
    }

    .section-row {
        padding: 0 1rem;
    }

    .card-user-info {
        padding-bottom: 0;
    }

    .card-user {
        align-items: center;
    }

    .blob-item .blob-title {
        margin-top: 3rem !important;
    }

    .main-footer {
        padding-top: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .footer-copy {
        margin-bottom: 1rem;
    }

    .footer-links {
        justify-content: unset;
    }

    .nav-socials {
        width: 100%;
        flex-wrap: wrap;
        margin-top: 1.5rem;
    }

    .error-text svg {
        width: 50px;
    }

    .footer-inner {
        padding: 20px;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }

    .footer-inner p {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 481px) and (max-width: 599px) {}

@media (min-width: 600px) and (max-width: 992px) {}

@media (min-width: 1000px) and (max-width: 1200px) {
    .row .col.xl2 {
        width: 24.7666666667%;
    }
}

@media (min-width: 1200px) {
    .row .col.xl2 {
        width: 19.8666666667%;
    }
}

@media only screen and (max-width: 1280px) {
    .hide-on-med-and-down {
        display: none;
        opacity: 0;
    }
}



