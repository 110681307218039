.slider__container {
    width: 100%;
    height: auto;
    position: relative;
}

.sli__der {
    display: none;
    height: auto;
}

.sli__der img {
    width: 1000px;
    height: 700px;
    transition: all .3s;
    object-fit: cover;
}

.sli__der__prev,
.sli__der__next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -22px;
    height: 30px;
    width: 30px;
    background-color: var(--cl-white);
    color: var(--cl-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border: none;
    /* border-radius: 15px; */
    user-select: none;
}

.sli__der__prev:hover,
.sli__der__prev:focus,
.sli__der__prev:active,
.sli__der__next:hover,
.sli__der__next:focus,
.sli__der__next:active {
    background-color: var(--cl-white);
}

.sli__der__next {
    right: 10px;
    border-radius: 15px;
}

.sli__der__prev {
    left: 10px;
    border-radius: 15px;
}

.sli__der__prev:hover,
.sli__der__next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 0px) and (max-width: 350px) {
    .sli__der img {
        height: 300px;
        width: 350px;
    }
}


@media (min-width: 350px) and (max-width: 480px) {
    .sli__der img {
        height: 350px;
        width: 480px;
    }
}

@media (min-width: 481px) and (max-width: 599px) {
    .sli__der img {
        height: 400px;
        width: 500px;
    }
}

@media (min-width: 600px) and (max-width: 992px) {
    .sli__der img {
        height: 450px;
        width: 600px;
    }
}