.blob-wrapper {
    width: 100%;
    margin-top: 55.8px;
    position: relative;
}

.blob-fixed {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 999;
}

.blob-fixed.bottom {
    bottom: 0;
}

.blob-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--cl-primary);
    font-family: "Lato", sans-serif;
    color: var(--tx-white);
    padding: .8rem 3rem;
    position: relative;
}

/* .blob-inner svg {
    color: var(--tx-white);
    fill: var(--tx-white);
} */

.blob-text {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.blob-text h4 {
    color: var(--tx-white);
    font-size: 1.2rem;
    margin: 0;
    font-weight: 500;
    letter-spacing: -.5px;
}

.blob-action {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blob-action button:first-child {
    background-color: var(--bg-white);
    color: var(--cl-primary);
    padding: .5rem 1rem;
    border: none;
    font-weight: 500;
    border-radius: 5px;
}

.r-float {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
}

.r-float:focus,
.r-float:focus-visible,
.r-float:focus-within,
.r-float:active {
    background-color: #eee;
}

/* .blob-content {
    width: 100%;
} */

.blob-content-items {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
}

.blob-extra {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 3rem 0;
    position: relative;
    background-color: var(--tag-bg);
}

.blob-extra .section-row {
    padding: 0;
}

/* .blob-extra-content {
    width: 100%;
} */

.blob-extra-items {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
}

.blob-hero-slider {
    padding: 4rem 0;
    background-color: var(--bg-black);
}

.blob-break-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .blob-break-view .blob-br {
    max-width: 1000px;
} */

.blob-item+.blob-item {
    margin-top: 2rem;
}

.blob-item.divide {
    margin-top: 3rem;
}

.blob-item.divide+.blob-item.divide {
    padding-top: 3rem;
    border-top: 1px solid rgb(227, 231, 240);
}


.blob-item .blob-title {
    color: var(--tx-black);
    font-size: 2.8rem;
    margin-top: 4rem;
    font-weight: 700;
    letter-spacing: -.5px;
    word-break: break-word;
}

.blob-item .blob-title[data-header-for="ratings"] {
    display: flex;
    justify-content: space-between;
}

.blob-item .blob-title[data-header-for="ratings"] span {
    border: 5px solid #ffa500;
    height: 60px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    border-radius: 50%;
    font-family: 'Lato', sans-serif;
}

.blob-item .blob-sub {
    font-family: 'Lato', sans-serif;
    color: var(--tx-main-light);
    font-size: 1.4rem;
    margin-top: 1rem;
    letter-spacing: -.5px;
    line-height: 24px;
}

.blob-item .blob-desc-area {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
}

.blob-item .blob-desc-area .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.blob-item .blob-desc-area .user-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.blob-item .blob-desc-area .user-name {
    font-size: 1rem;
    color: var(--tx-black);
}

.blob-item .blob-desc-area .user-name-info {
    display: flex;
    align-items: center;
    gap: 4px;
}

.blob-item .blob-desc-area .user-name-info .user-badge {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.blob-item .blob-desc-area .user-name-info .user-badge img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.blob-item .blob-desc-area .blob-properties {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.blob-lt {
    color: var(--tx-main-light);
    font-size: .85rem;
}

.blob-lt .blob-tag {
    color: var(--tx-black);
}

.blob-lt+.blob-lt {
    margin-left: 10px;
}

.blob-item .blob-action-area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    border-top: 1px solid rgb(227, 231, 240);
    border-bottom: 1px solid rgb(227, 231, 240);
}

.blob-item .blob-action-area .action-lr,
.blob-item .blob-action-area .action-rl {
    display: flex;
    align-items: center;
}

.l+.l {
    margin-left: 1rem;
}

.l span {
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--tx-main-light);
    font-size: .9rem;
}

.r+.r {
    margin-left: 1.4rem;
}

.blob-item .blob-img-area {
    width: 100%;
}

.blob-item .blob-img-area img {
    max-width: 100%;
    max-height: 700px;
}

.blob-item .blob-text-area h1,
.blob-item .blob-text-area h2,
.blob-item .blob-text-area h3,
.blob-item .blob-text-area h4,
.blob-item .blob-text-area h5,
.blob-item .blob-text-area h6 {
    font-size: 1.6rem;
    color: var(--tx-black);
    font-weight: 700;
    letter-spacing: -.5px;
    font-family: 'Lato', sans-serif;
}

.blob-item .blob-text-area ul {
    list-style: disc;
    margin: 2rem 0;
    margin-left: 3rem;
    font-family: 'Lato', sans-serif;
    color: var(--tx-main-light);
    font-size: 1.3rem;
    line-height: 30px;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.blob-item .blob-text-area ul li {
    font-family: 'Open Sans', sans-serif;
    padding-left: .5rem;
    margin-top: 10px;
    font-weight: 300;
}

.blob-item .blob-text-area p,
.blob-item .blob-text-area i,
.blob-item .blob-text-area span {
    font-family: 'Lato', sans-serif;
    color: var(--tx-main-light);
    font-size: 1.3rem;
    line-height: 30px;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin: 2rem 0;
}

.blob-item .blob-text-area p strong {
    font-weight: bolder !important;
}

.blob-item .blob-text-area blockquote::before {
    content: "\201C";
    font-size: 4em;
    left: 10px;
    position: absolute;
    top: -10px;
}


.blob-item .blob-text-area blockquote {
    border: none;
    font-size: 1.3rem;
    font-weight: 300;
    padding: 1.2em 30px 1.2em 75px;
    position: relative;
    font-family: 'Lato', sans-serif;
}

.blob-tag-area h4 {
    margin-top: 0;
    font-size: 1.3rem;
    display: flex;
    margin: 0;
    align-items: center;
    color: var(--tx-black);
    font-weight: 600;
    letter-spacing: -.5px;
}

.blob-extra .blob-tag-area h4 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.blob-item .blob-tag-area .blog-mult {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blob-item .blob-tag-area .blog-mult a {
    color: var(--tx-main-light);
}

.blob-item .blob-tag-area .blog-mult .count {
    line-height: normal;
    margin-left: 8px;
    color: var(--tx-main-light);
    font-size: .9rem;
}

.blob-item .blob-tag-lists {
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.blob-item .blob-list {
    background: var(--tag-bg);
    padding: 8px 10px;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    color: var(--tx-main-light);
    font-size: .9rem;
    font-weight: 300;
}

.blob-list a {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--tx-main-light);
}

.blob-list button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    color: var(--tx-main-light);
}

.blob-list button:focus,
.blob-list button:focus-visible,
.blob-list button:focus-within,
.blob-list button:active {
    background-color: transparent;
}

.blob-list+.blob-list {
    margin-left: 10px;
}

.blob-button {
    margin-top: 2rem;
    background-color: var(--cl-primary);
    color: var(--tx-white);
    border: none;
    padding: 12px;
    border-radius: 4px;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    width: fit-content;
}

.form-area {
    width: 100%;
    margin-top: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
}

.form-title {
    color: var(--tx-black);
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
}

.form-title.sm {
    font-size: 1rem;
}

.form-area .form-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.form-area textarea {
    border-radius: 5px;
    padding: 10px;
    height: 100px;
    border: 1px solid #e3e3e3;
}

textarea:focus,
textarea:focus-visible,
textarea:focus-within {
    outline: 1px solid var(--tx-black);
}

.blob-button:focus,
.blob-button:focus-visible,
.blob-button:focus-within,
.blob-button:active {
    background-color: var(--cl-primary);
}

.blob-comment-lists {
    margin-top: 2rem;
}

.comment-list+.comment-list {
    margin-top: 2rem;
}

.comment-list#load__er {
    margin-top: 2rem;
}

.wrapper-fx-col {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.blob-comment-lists .blob-desc-area {
    align-items: flex-start;
}

.wrapper-fx-row {
    display: flex;
    align-items: center;
}

.wrapper-fx-row.j-sb {
    justify-content: space-between;
}

.m-x {
    margin-top: 1rem;
}

.blob-comment-lists .comment-action {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
    border: none;
}

.blob-sort {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
}

.blob-sort p {
    text-transform: uppercase;
}

.blob-sort .sort {
    margin-left: 1rem;
    color: var(--tx-main-light);
}

.blob-sort .sort.active,
.blob-sort .sort.active svg {
    color: var(--tx-black);
}

.blob-sort .comment-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
    border: none;
}

button {
    cursor: pointer !important;
}

.comment-action.fx-row {
    flex: unset;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: .85rem;
    color: var(--tx-main-light);
}

.comment-textbox {
    word-wrap: break-word;
    margin-left: 5px;
    background: var(--tag-bg);
    color: #292929;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    margin-top: 1rem;
    padding: 1rem;
    word-break: break-all;
}

.gap {
    gap: 10px;
}

.upper-tx {
    text-transform: uppercase;
}

.blob-connect {
    margin-top: 2rem;
    background-color: var(--tag-bg);
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    padding: 20px 15px;
}

.blob-connect h5 {
    margin-top: 0;
    font-size: 1.2rem;
    display: flex;
    margin: 0;
    align-items: center;
    color: var(--tx-black);
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    letter-spacing: -.2px;
}

.blob-connect .price {
    margin: .5rem 0;
    font-weight: 600;
    color: var(--cl-secondary);
}

.blob-connect .blob-tag-lists {
    margin-top: 20px;
}

.disclaimer {
    font-family: 'Lato', sans-serif;
    margin-top: 1rem;
    font-size: .9rem;
    display: flex;
    flex-direction: column;
}

.disclaimer i {
    margin-right: 5px;
    color: var(--tx-black);
    text-decoration: underline;
}

.disclaimer a {
    margin-left: 3px;
    font-style: italic;
    color: var(--tx-main-light);
    text-decoration: underline;
}

.disclaimer span {
    margin-top: 10px;
    color: var(--tx-main-light);
}

.blob-rate {
    margin-top: .5rem;
    float: left;
}

.blob-rate:not(:checked)>input {
    position: absolute;
    left: -9999px;
}

.blob-rate__type {
    color: var(--text-main);
}

.blob-rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 15px;
    color: #ccc;
    padding-left: 18px;
    transition: .1s;
}

.blob-rate:not(:checked)>label:before {
    content: '★ ';
    border: none;
}

.blob-rate>input:checked~label {
    color: #ffc700 !important;
}

.blob-rate:not(:checked)>label:hover,
.blob-rate:not(:checked)>label:hover~label {
    color: #deb217 !important;
}

.blob-rate>input:checked+label:hover,
.blob-rate>input:checked+label:hover~label,
.blob-rate>input:checked~label:hover,
.blob-rate>input:checked~label:hover~label,
.blob-rate>label:hover~input:checked~label {
    color: #c59b08 !important;
}

.blob-rate>input:checked+label::after {
    background-color: transparent;
    border: none;
}

.blob-row {
    margin-bottom: 0;
}

.blob-progress-bar,
.blob-progress-desc {
    margin-top: 2rem;
}

.blob-cr__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--tx-black);
    font-family: 'Lato', sans-serif;
}

.blob-cr__info+.blob-cr__info {
    margin-top: 1rem;
}

.blob-cr__info[data-overview="housing"]+.blob-cr__info[data-overview="housing"] {
    margin-top: 1.4rem;
}

.blob-info__title {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.blob-info__rating {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blob-info__rating .fa {
    color: var(--cl-primary);
}

@media (max-width: 480px) {
    .blob-inner {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .blob-text {
        gap: 1rem;
    }

    .blob-text h4 {
        font-size: 1.1rem;
    }

    .r-float {
        top: 5px;
        right: 5px;
    }
    
}