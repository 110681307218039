.form-pd {
    padding: 0 2rem;
}

.form-control {
    margin-top: 1rem;
    width: 100%;
}

.text-area{
    font-size: .9rem;
    color: var(--tx-main-light);
    font-family: 'Lato', sans-serif;
    margin-bottom: 1rem;
}

.form-group {
    width: 100%;
    font-family: 'Lato', sans-serif;
    position: relative;
}

.form-group+.form-group {
    margin-top: .5rem;
}

.form-group label {
    color: var(--tx-main-light);
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
}

.form-group input {
    width: 100%;
    padding: .8rem;
    line-height: normal;
    border-radius: 5px;
    border: 1px solid var(--tx-main-light);
}

.form-group .form-btn {
    width: 100%;
    padding: .8rem;
    line-height: normal;
    border-radius: 5px;
    border: none;
}

.form-btn#submit__main {
    background-color: var(--cl-primary);
    color: var(--tx-white);
}

.form-error {
    margin-top: 2px;
    color: var(--tx-warning);
    font-size: .9rem;
}

.form-group input:focus,
.form-group input:active,
.form-group input:focus-within,
.form-group textarea:focus,
.form-group textarea:active,
.form-group textarea:focus-within {
    border: 1px solid var(--cl-secondary);
    outline: none;
}

.form-options {
    margin-top: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-options p {
    font-size: .9rem;
    color: var(--tx-main-light);
    display: flex;
    align-items: center;
    gap: 5px;
}

.form-options a {
    text-decoration: underline;
    color: var(--tx-main-light);
    font-size: .9rem;
}

.form-divided {
    margin: 2rem 0;
    display: flex;
    align-items: center;
}

.form-divided span:nth-child(2) {
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-divided .b-top {
    flex: 1;
    border-top: .5px solid var(--tx-main-light);
}

.form-others {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.form-other {
    flex: 1;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e3e3e364;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    position: relative;
}

.form-other:focus,
.form-other:active,
.form-other:hover,
.form-other:focus-within {
    background: #e3e3e364;
    outline: 1px solid dodgerblue;
}

.form-other div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.form-other svg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
}

.form-other p {
    flex: 1;
    font-size: 1rem;
    font-weight: 500;
    color: var(--tx-black);
}