.forum-fixed-header {
    position: fixed;
    z-index: 11;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    background-color: var(--cl-white);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 -71px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.forum-lists {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--tx-main-light);
}

.forum-list {
    padding: 10px 10px 10px 0px;
}

.forum-list a {
    color: var(--tx-main-light);
}

.forum-list.active {
    color: var(--tx-black);
    border-bottom: 3px solid var(--tx-black);
}

.forum-list.active a {
    color: var(--tx-black);
    font-weight: bold;
}

/* c forum */
.community-forum {
    width: 100%;
    position: relative;
    margin-top: 55.8px;
}

.hero-area {
    width: 100%;
    height: 450px;
    position: relative;
}

.search-form,
.form-container {
    width: 100%;
}

.form-container {
    background-color: #eee;
    padding: 10px 15px;
    border-radius: 30px;
    border: 1px solid #eee;
    gap: 10px;
    display: flex;
    align-items: center;
}

.form-container input{
    width: 100%;
    padding: 5px;
    border: none;
    background-color: #eee;
}

.form-container input::placeholder{
    font-size: 1.3rem;
    font-family: 'Lato', sans-serif;
}

.form-container input:focus-visible{
    outline: none;
}

.hero-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
}

.hero-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-info {
    margin-top: 4rem;
}

.hero-content .hero-title {
    font-weight: 700;
    letter-spacing: -.5px;
    transition: all 1s;
}

.hero-content .hero-desc {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
    max-width: 50ch;
}

.hero-actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(238, 238, 238);
}

.hero-tag-lists {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: scroll hidden;
    gap: 2rem;
}

.hero-tag-lists::-webkit-scrollbar {
    display: none;
}

.hero-tag-lists .tag-list {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
}

.hero-tag-lists .tag-list a {
    padding: 1.57rem 0;
    color: var(--tx-main-light);
    white-space: pre;
}

.hero-tag-lists .tag-list a.active {
    color: var(--tx-black);
    border-bottom: 2px solid var(--tx-black);
}

.hero-tag-lists .tag-list.filter a.active {
    color: var(--tx-black);
    border-bottom: none;
}

.hero-auth-area {
    /* width: 120px; */
    display: flex;
    justify-content: flex-end;
    background-color: var(--bg-white);
}

.v-forum-lists {
    margin-top: 2rem;
}


.v-forum-lists .section-row {
    padding: 0;
}

.v-skill-lists {
    margin-top: 2rem;
}

.v-skill-lists .section-row {
    padding: 0;
}

.v-skill-lists .skill-card {
    border: none;
}

.v-skill-lists .skill-info-area {
    padding: 0;
    padding-top: 10px;
}

.v-skill-lists .skill-tag {
    background: rgba(195, 200, 212, 0.2);
    color: rgb(91, 102, 120);
}

.v-skill-lists .skill-tag a {
    color: var(--tx-black);
}

.v-skill-lists .skill-banner-area img {
    border-radius: 10px;
}