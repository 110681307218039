.auth-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
}

.auth-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.auth-sections {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.auth-hero {
    width: 100%;
    height: 20vh;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/voisapp-5deb7.appspot.com/o/misc%2Fvc1.png?alt=media&token=772a00ae-3206-43d6-ad3c-456eebca78a8');
    background-position: top;
    /* background-size: cover; */
    position: relative;
}

.auth-hero svg {
    position: absolute;
    top: 1.5rem;
    left: 10%;
}

.auth-main {
    margin-top: 2rem;
    flex: 1;
    display: flex;
    justify-content: center;
}

.auth-main-items {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.auth-routes {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.auth-routes a {
    font-family: 'Lato', sans-serif;
    font-size: .8rem;
    text-decoration: none;
    color: var(--tx-main-light);
    text-transform: uppercase;
}

.auth-main-title {
    text-align: center;
    font-size: 1.4rem;
    letter-spacing: -.05px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
    color: var(--tx-black);
}

.auth-main-sub {
    text-align: center;
    font-size: 1rem;
    letter-spacing: -.05px;
    line-height: normal;
    font-family: "Lato", sans-serif;
    color: var(--tx-main-light);
}

.auth-main-content {
    width: 100%;
}

.auth-lists {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.auth-list {
    width: 100%;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
}

.auth-list+.auth-list {
    margin-top: 1rem;
}

.auth-list a {
    display: block;
    padding: 1.5rem;
    color: var(--tx-main-light);
}

.auth-list .auth-option {
    color: var(--tx-black);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: normal;
}

.auth-list .auth-option span {
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 5px;
}

.auth-list-info {
    font-size: .8rem;
    display: flex;
    line-height: normal;
    margin-top: 1rem;
}

.auth-footer {
    width: 100%;
    padding: 1rem 0;
    color: var(--tx-main-light);
}

.auth-footer-navs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-nav {
    font-size: .85rem;
    font-weight: 300;
}

.auth-nav a {
    color: var(--tx-main-light);
    text-decoration: underline;
    font-weight: 500;
}

.auth-nav:first-child {
    font-size: .9rem;
}

.auth-nav+.auth-nav {
    margin-left: .7rem;
}