/* basic styling */


.skeleton {
    background: var(--tag-bg);
    margin: 10px 0;
    border-radius: 4px;
}

.skeleton.text {
    width: 50%;
    height: 12px;
}

.skeleton.profile {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.skeleton.post_mini {
    width: 50px;
    height: 50px;
    border-radius: 8px;
}

.skeleton.username {
    width: 60px;
    height: 12px;
}

.skeleton.title {
    width: 80%;
    height: 20px;
}

.skeleton.banner {
    width: 150px;
    height: 150px;
    border-radius: 8px;
}

.skeleton.banner.lg {
    width: 100%;
    height: 12rem;
    border-radius: 0px;
    margin: 0;
}

.skeleton__wrapper {
    padding: 10px;
    background-color: var(--bg-white);
    position: relative;
    overflow: hidden;
}

.skeleton__forum {
    display: flex;
    flex-direction: column;
}

.skeleton__wrapper .skeleton__fh {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
}

.skeleton__wrapper .skeleton__bd {
    display: flex;
    height: 100%;
    gap: 12px;
}

.skeleton__sh {
    width: 100%;
    display: flex;
    align-items: center;
}

.skeleton__sb {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.skeleton__wrapper .skeleton__bd div:nth-of-type(1) {
    flex: 1;
}

.skeleton__wrapper .skeleton__bd div:nth-of-type(1) div:nth-of-type(3) {
    display: flex;
    align-items: center;
    margin-top: 60px;
    width: fit-content;
    gap: 12px;
}

.sk__animate__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: loading 2.5s infinite;
}

.sk__animate {
    width: 40%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
}

@keyframes loading {
    0% {
        transform: translateX(-150%)
    }

    50% {
        transform: translateX(-60%)
    }

    100% {
        transform: translateX(150%)
    }
}

@media (max-width: 480px) {
    .skeleton__wrapper {
        padding: 0;
    }

    .skeleton__forum {
        flex-direction: row;
        gap: 10px;
    }

    .skeleton__wrapper .skeleton__fh {
        width: unset;
    }

    .skeleton__wrapper .skeleton__bd {
        width: 100%;
    }

    .skeleton.banner.lg {
        width: 90px;
        height: 90px;
    }
}