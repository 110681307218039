.auth-btn {
    background-color: var(--cl-primary);
    color: var(--tx-white);
    padding: 10px 14px;
    border: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 40px;
    font-family: 'Open Sans', sans-serif;
}

.auth-btn-text {
    font-size: .9rem;
}

.auth-btn:focus-visible,
.auth-btn:focus,
.auth-btn:active {
    background-color: var(--cl-primary);
}

.auth-blob-btn {
    background-color: var(--bg-white);
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: var(--tx-main-light);
    font-size: .8em;
    font-family: 'Open Sans', sans-serif;
}

.auth-blob-btn:focus-visible,
.auth-blob-btn:focus,
.auth-blob-btn:active {
    background-color: var(--bg-white);
}