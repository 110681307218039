@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
    --cl-primary: #293aa6;
    --cl-secondary: #ff4e00;
    --cl-black: #131313;
    --bg-black: #131313;
    --bg-white: #fff;
    --tx-main-light: #666666;
    --tx-black: rgb(0, 0, 0, 87%);
    --tx-white: #fff;
    --cl-white: #fff;
    --cl-star: #ffbe5b;
    --tag-bg: #fafafa;
    --tx-warning: #c94a4a;
    --l-width: 1px;
    --tx-inactive: rgb(102, 102, 102, 30%)
}

.cl-icon-p {
    color: var(--cl-primary);
}

.fl-icon-p {
    fill: var(--cl-primary);
}

.pd-lr {
    padding: 0 3rem;
}

.pd-tb {
    padding: 20px 0;
}

.cl-black {
    color: var(--tx-black);
}

.cl-icon-light {
    color: var(--tx-main-light);
}

.cl-icon-white {
    color: var(--tx-white);
}

.p15 {
    padding: 0 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

button[disabled],
html input[disabled] {
    cursor: not-allowed;
    opacity: .7;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.tx-warning {
    color: var(--tx-warning) !important;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.root-layout,
.main-layout {
    background-color: var(--bg-white);
}

/* img::before {
    content: '';
    width: 100%;
    height: calc(100% + 10px);
    background-color: var(--tag-bg);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
} */

img {
    position: relative;
}

/* img::after {
    content: "\2639";
    font-size: 18px;
    color: var(--tx-main-light);
    display: block;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.container .row {
    margin-left: 0;
    margin-right: 0;
}

.body {
    width: 100%;
    position: relative;
    font-size: 15px;
    background-color: var(--bg-white);
}

.btn-plain {
    background: transparent;
    border: none;
}

.main-header {
    background-color: var(--bg-white);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    color: var(--tx-main-light);
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.main-header.contain-layout {
    padding: 10px 0;
}

.header-area {
    display: flex;
    align-items: center;
}

.max-area {
    max-width: 700px;
}

.header-nav {
    background-color: var(--bg-white);
    height: unset;
    line-height: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--tx-main-light);
    font-family: 'Lato', sans-serif;
}

.logo-search {
    color: var(--tx-main-light);
    display: flex;
    align-items: center;
}

.nav-search {
    margin-left: 2rem;
    min-width: 300px;
    max-width: 350px;
}

.nav-input {
    width: 100%;
    height: 34px;
    border-radius: 40px;
    padding-left: 1rem;
    border: 1px solid #eee;
    color: var(--tx-main-light);
}

.nav-input:focus-visible {
    outline: none;
}

.header-nav li a {
    color: var(--tx-main-light);
}

.header-nav li a:hover {
    background-color: transparent;
}

.header-nav i {
    line-height: unset;
    height: unset;
}

.nav-links,
.nav-link a {
    display: flex;
    align-items: center;
}

.nav-link a {
    color: var(--tx-main-light);
}

.nav-link:last-child a {
    padding-right: 0;
}

.nav-link-btn {
    background-color: var(--cl-primary);
    color: var(--cl-white);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: .7rem .6rem;
    border: none;
    font-size: .9rem;
    border-radius: 6px;
}

.nav-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.nav-profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 14px 16px;
    font-family: 'Lato', sans-serif;
}

.nav-profile p {
    line-height: normal;
}

.nav-profile .nav-user {
    font-size: 1rem;
    color: var(--tx-black);
}

.nav-profile .nav-mail {
    font-size: .85rem;
    margin-top: 3px;
    color: var(--tx-main-light);
}


.nav-link-btn:active,
.nav-link-btn:focus {
    background-color: var(--cl-primary);
}

.dropdown-content {
    background-color: var(--bg-white);
}

.dropdown-content li:hover,
.dropdown-content li.active {
    background-color: var(--bg-white);
}

.dropdown-content.custom__dropdown {
    top: 52px !important;
    min-width: 180px;
    border-radius: 10px;
}

.dropdown-content.custom__dropdown.nav-drop {
    min-width: 250px;
    border-radius: 10px;
}

.dropdown-content.custom__dropdown.nav-notification {
    min-width: 320px;
    max-width: 320px;
    display: none;
    padding: 1rem 0;
    border-radius: 10px;
    height: unset !important;
}

.dropdown-content.nav-drop a {
    padding: 10px 16px;
    color: var(--tx-main-light);
}

.dropdown-content .nav-profile .nav-btn {
    border: 1px solid var(--tx-black);
    margin-top: 10px;
    padding: 5px 7px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.dropdown-content.badge {
    border-radius: 5px;
}

.dropdown-content li a {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    padding: 14px 16px;
    color: var(--tx-main-light);
}

.dropdown-content .custom__list {
    display: flex;
    align-items: center;
    gap: 10px;
    /* min-height: 20px; */
}

/* notifications */
.notification-wrapper {
    display: flex;
    flex-direction: column;
}

.notification-title {
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: -.123px;
    color: var(--tx-black);
    padding: 0 1rem;
    padding-top: .4rem;
}

.notification-body {
    display: flex;
    flex-direction: column;
}

.icon-area {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    border-radius: 50%;
    margin-top: 1rem;
}

.icon-info {
    font-size: 1.1rem;
    font-family: 'Lato', sans-serif;
    margin-top: 1rem;
}

.notification-lists {
    margin-top: 1rem;
}

.n-list {
    font-family: 'Lato', sans-serif;
    padding: .5rem 1rem;
}

.n-list+.n-list {
    border-top: 1px solid #eee;
}

.n-list .info {
    color: var(--tx-black);
    font-size: .9rem;
}

.n-list .info a {
    font-size: .9rem;
}

.n-list .created {
    color: var(--tx-main-light);
    font-size: .85rem;
}

/* modal */
/* .modal__box {
    width: 35%;
} */
.modal-overlay {
    background-color: var(--bg-white);
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px;
}

.modal .modal-content {
    padding: 0;
    width: 70%;
    margin: 0 auto;
}

.modal-content h4 {
    margin: 0;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: -.123px;
}

.modal-content p {
    margin-top: 1rem;
    text-align: center;
    line-height: normal;
    font-family: 'Lato', sans-serif;
    color: var(--tx-main-light);
}

.modal-content .inner-content {
    margin-top: 24px;
}

.modal-content .inner-content label {
    color: var(--tx-black);
}

.btn-modal.plain {
    background-color: transparent;
    border: none;
}

.btn-modal.plain:active,
.btn-modal.plain:focus,
.btn-modal.plain:focus-visible {
    background-color: transparent;
}

.modal .modal-footer {
    padding: 24px;
    padding-bottom: 48px;
    height: unset;
}

.modal-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.a-btn-modal {
    padding: 10px 15px;
    border-radius: 20px;
}

.a-btn-modal.b-only {
    border: 1px solid var(--tx-black);
    background-color: transparent;
}

.a-btn-modal.bg-only {
    border: none;
}

.a-btn-modal.bg-only.danger {
    background-color: var(--tx-warning);
    color: var(--tx-white);
}

/* badge */
.dp-badge-info {
    padding: 1rem;
}

.dp-badge-title {
    margin-top: 0;
    font-family: 'Lato', sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: -.411px;
}

.dp-badge-info .fa {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tx-main-light);
}

.dp-badge-info p {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: normal;
    color: var(--tx-main-light);
}

.dp-badge-info p img {
    width: 30px !important;
    height: 30px !important;
    object-fit: cover !important;
}

.fx-fade {
    animation-name: fadeEffect;
    animation-duration: 1s;
}

.auth-active a.active {
    color: var(--cl-secondary);
}

.down__slide__fx {
    animation: downslideEffect .8s ease;
}

.up__slide__fx {
    animation: upslideEffect .8s ease;
}

.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cl-primary);
    opacity: .8;
    z-index: 999;
}

.loader-wrapper.cover {
    background-color: white;
    opacity: 1;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid grey;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

.loader-wrapper.cover .loader {
    width: 50px;
    height: 50px;
}

#custom-hidden {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}

/* Abort Toast */
#toast-container {
    min-width: 10%;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    height: fit-content;
}

.toast {
    border-radius: 2px;
    top: 0;
    width: auto;
    clear: both;
    margin-top: 0px;
    position: relative;
    max-width: 100%;
    height: auto;
    min-height: 25px;
    text-align: center;
    line-height: normal;
    word-break: keep-all;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toast .toast-action {
    height: 25px;
    line-height: 25px;
}

.error-area {
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lato', sans-serif;
}

.error-text p {
    text-transform: uppercase;
    color: var(--tx-black);
}

.error-text h3 {
    text-transform: uppercase;
    color: var(--tx-main-light);
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:before,
[type="radio"].with-gap:checked+span:after {
    border: 2px solid var(--tx-black);
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:after {
    background-color: var(--tx-black);
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeEffect {
    from {
        opacity: .4;
    }

    to {
        opacity: 1;
    }
}

@keyframes downslideEffect {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes upslideEffect {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@media (max-width: 480px) {
    .refix {
        translate: 100px 0 0 !important;
    }
}