/* raritng component */
.custom-rating-wrapper {
    display: flex;
    align-items: center;
    gap: .5rem;
    color: var(--tx-main-light);
    font-size: .9rem;
}

.custom-rating-mask {
    width: 15px;
    height: 15px;
    position: relative;
    cursor: pointer;
}

.custom-rating-img,
.custom-rating-mask::before {
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/star.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.custom-rating-img {
    filter: grayscale(100%);
}

.custom-rating-mask::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    clip-path: circle(0px at left center);
    transition: all .3s;
    z-index: 1;
}

.custom-rating-mask[data-star="1"]::before {
    clip-path: circle(20px at left center);
}

.custom-rating-mask[data-star="0.1"]::before {
    clip-path: circle(2px at left center);
}

.custom-rating-mask[data-star="0.2"]::before {
    clip-path: circle(4px at left center);
}

.custom-rating-mask[data-star="0.3"]::before {
    clip-path: circle(6px at left center);
}

.custom-rating-mask[data-star="0.4"]::before {
    clip-path: circle(8px at left center);
}

.custom-rating-mask[data-star="0.5"]::before {
    clip-path: circle(10px at left center);
}

.custom-rating-mask[data-star="0.6"]::before {
    clip-path: circle(12px at left center);
}

.custom-rating-mask[data-star="0.7"]::before {
    clip-path: circle(14px at left center);
}

.custom-rating-mask[data-star="0.8"]::before {
    clip-path: circle(16px at left center);
}

.custom-rating-mask[data-star="0.9"]::before {
    clip-path: circle(18px at left center);
}

.rating__comp {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.rating__desc {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.rating__desc .rating__count {
    color: orange !important;
}

/* content ratinf */
.blob-rating {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: 'Lato', sans-serif;
}

.blob-rating+.blob-rating{
    margin-top: 1rem;
}

.blob-rating .rating__stats {
    display: flex;
    align-items: center;
    gap: .4rem;
}

.rating__stats .rating__order {
    width: 56px;
    font-size: .8rem;
}

.rating__stats .rating__reviews {
    width: 40px;
    font-size: .8rem;
    font-weight: 300;
}

.rating__stats .rating__order,
.rating__reviews {
    font-size: 1rem;
    color: var(--tx-black);
}

.rating__stats .rating__bar {
    flex: 1;
    height: .6rem;
    border-radius: .3rem;
    background-color: var(--tx-inactive);
    display: flex;
}

.rating__bar .rating__width {
    border-radius: .3rem;
    background-color: var(--cl-primary);
}