.create-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: var(--bg-white);
}

.create-items {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.create-btn{
    border: .5px solid var(--cl-black);
    background-color: transparent;
    font-size: 1.2rem;
    padding: 8px 10px;
    margin-right: 1rem;
}

.create-header {
    padding: .5rem 0;
}

.create-header .header-content {
    display: flex;
    align-items: center;
}

.header-content .header-lists {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* justify-content: center; */
/* overflow-x: auto; */

.create-main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.create-content {
    max-width: 600px;
    width: 100%;
}

.content-title {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    font-weight: 600;
    letter-spacing: -.5px;
    transition: all .3s;
}

.content-error{
    background-color: #eee;
    padding: 2rem;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
}

.content-error p{
    font-size: 1rem;
    color: var(--tx-black);
}

.create-float {
    position: fixed;
    right: 50px;
    bottom: 100px;
}

.create-float svg{
    width: 350px;
    height: 300px;
}

.content-box {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* border: .5px solid #e3e3e3; */
    padding: 2rem;
}

.content-row {
    margin-top: 1rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.content-row .content-col {
    padding: 0;
}

.content-input+.content-input {
    margin-top: 1rem;
}

.content-input .label,
p.label,
.content-input label {
    font-size: .9rem;
    color: var(--tx-black);
}

.content-input input {
    border-bottom: 1px solid #e3e3e3 !important;
}

.content-input input:focus {
    border-bottom: 1px solid #e3e3e3 !important;
    box-shadow: none !important;
}

.content-input textarea {
    border: none;
    border-bottom: 1px solid #e3e3e3;
    min-height: 80px;
    padding: 10px 0;
    resize: none;
}

.content-input textarea:focus,
.content-input textarea:active,
.content-input textarea:focus-visible {
    outline: none;
}

.content-input .switch {
    margin-top: 1rem;
}

.content-box+.content-box {
    margin-top: 2rem;
}

.content-editor {
    flex: 1;
    min-height: 100px;
}

.editor-container {
    width: 100%;
    font-size: 1.3rem;
    position: relative;
    padding-bottom: 1rem;
}

.editor-container .ql-editor {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 10px;
    color: var(--tx-main-light);
    position: relative;
}

.ql-editor.ql-blank::before {
    position: absolute;
    top: 0;
    left: 0;
    content: attr(data-placeholder);
    font-size: 1.2rem;
    font-weight: 200;
}

.editor-container .ql-clipboard {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    padding: 0 10px !important;
    border-radius: 10px;
}

.editor-container .ql-editor:focus-visible {
    outline: var(--bg-white);
}

.ql-clipboard:focus-within {
    outline: var(--bg-white);
}


.editor-container .ql-editor h1,
.editor-container .ql-editor h2,
.editor-container .ql-editor h3,
.editor-container .ql-editor h4,
.editor-container .ql-editor h5,
.editor-container .ql-editor h6 {
    font-size: 1.7rem;
    color: var(--tx-black);
    font-weight: 700;
    letter-spacing: -.5px;
}

.editor-container .ql-editor ul {
    list-style: disc;
    margin: 2rem 0;
    margin-left: 3rem;
    font-family: 'Lato', sans-serif;
    color: var(--tx-main-light);
    font-size: 1.3rem;
    line-height: 30px;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.editor-container .ql-editor ul li {
    font-family: 'Open Sans', sans-serif;
    padding-left: .5rem;
    margin-top: 10px;
    font-weight: 300;
}

.editor-container .ql-editor p,
.editor-container .ql-editor i,
.editor-container .ql-editor span {
    font-family: 'Lato', sans-serif;
    color: var(--tx-main-light);
    font-size: 1.3rem;
    line-height: 30px;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin: 2rem 0;
}

.editor-container .ql-editor p strong {
    font-weight: bolder !important;
}

.editor-container .ql-editor blockquote::before {
    content: "\201C";
    font-size: 4em;
    left: 10px;
    position: absolute;
    top: -10px;
}


.editor-container .ql-editor blockquote {
    border: none;
    font-size: 1.3rem;
    font-weight: 300;
    padding: 1.2em 30px 1.2em 75px;
    position: relative;
    font-family: 'Lato', sans-serif;
}


.content-editor textarea {
    min-height: 100px;
    border: none;
    resize: none;
}

.content-editor textarea:focus {
    outline: none;
}

.content-editor h1 {
    font-size: 1.4rem;
    margin: 0;
    font-weight: lighter;
    letter-spacing: -.5px;
    transition: all .3s;
    color: var(--tx-main-light);
}

.content-editor-props {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Lato', sans-serif;
    color: var(--tx-black);
}

.editor-style {
    display: flex;
    align-items: center;
}

.editor-style .btn-plain:active,
.editor-style .btn-plain:focus,
.editor-style .btn-plain:focus-visible {
    background-color: transparent;
}

.content-editor-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #e3e3e3;
}

.btn-next {
    background-color: var(--cl-primary);
    color: var(--tx-white);
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
}

.btn-next:active,
.btn-next:focus,
.btn-next:focus-visible {
    background-color: var(--cl-primary);
}


.editor-style li+li {
    margin-left: 1rem;
}

.forum-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
    gap: 1rem;
}

.list-btn {
    background-color: var(--bg-white);
    color: var(--tx-black);
    border: 1px solid #e3e3e3;
    padding: 6px 8px;
    border-radius: 4px;
}

.forum-tags p {
    display: flex;
    align-items: center;
    font-size: .9rem;
    gap: 5px;
}

.forum-tags p span {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-width: 70px;
    max-height: 20px;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.forum-tags p button {
    display: flex;
    align-items: center;
    background-color: var(--bg-white);
    border: none;
}

.forum-tags p button svg {
    color: var(--tx-black);
}

.list-btn.selected {
    background-color: #e3e3e3;
}

.list-btn:active,
.list-btn:focus,
.list-btn:focus-visible {
    background-color: var(--bg-white);
}

.forum-tags p button:active,
.forum-tags p button:focus,
.forum-tags p button:focus-visible {
    background-color: var(--bg-white);
}



.list-btn.selected:active,
.list-btn.selected:focus,
.list-btn.selected:focus-visible {
    background-color: #e3e3e3;
}

.poster-area {
    height: 50px;
    background-color: #e3e3e3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.editor-featured-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
}

.editor-featured-image {
    width: 100%;
    height: 100%;
}

.editor-featured-image img {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.editor-featured-upload {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: transparent;
    border: .5px dashed #e3e3e3;
}

.editor-featured-upload input[type="file"] {
    display: none;
}

.editor-featured-upload .featured-label {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    cursor: pointer;
    color: var(--tx-main-light);
    font-weight: 500;
}

.poster-action {
    display: flex;
    align-items: center;
    gap: 10px;
}

.poster-action button {
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    font-size: .8rem;
}

.create-footer {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
}

.footer-inner {
    max-width: 600px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9rem;
    font-family: 'Lato', sans-serif;
}

.footer-inner a {
    color: var(--tx-black);
    text-decoration: underline;
}

@media (max-width: 480px) {

    .content-box {
        padding-left: 0;
        padding-right: 0;
    }
}