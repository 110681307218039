.page-wrapper {
    width: 100%;
    padding-top: 55.8px;
    position: relative;
    background-color: var(--tag-bg);
}

.page-content {
    max-width: 700px;
    display: flex;
    flex-direction: column;
}

.page-header {
    margin-top: 2rem;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
    letter-spacing: -.5px;
    transition: all .5s;
}

.page-box {
    background-color: var(--bg-white);
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.page-box-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: -.5px;
    padding: 0 1rem;
}

.collapsible.wrapper {
    margin: 0;
    border: 0;
    box-shadow: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.collapsible-header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.collapsible-item:last-child .collapsible-header {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.collapsible.wrapper a:first-child .collapsible-header{
    border-radius: 0;
}

.collapsible.wrapper a:last-child .collapsible-header {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.box-collapsible {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.caret-area {
    display: flex;
    align-items: center;
    justify-content: center;
}

.caret-area svg {
    transform: rotate(90deg);
    transition: .5s all;
}

.caret-area.link svg {
    transform: unset;
}

.caret-area svg.active {
    transform: rotate(-90deg);
}

.collapsible-header p {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--tx-black);
    line-height: normal;
    font-size: .9rem;
}

.collapsible-header p span {
    line-height: normal;
    font-family: "Open Sans", sans-serif;
    font-size: .8rem;
    color: var(--tx-main-light);
}

.switch label .lever:before,
.switch label .lever:after {
    display: flex;
    align-items: center;
    top: 2px;
    left: 2px;
}

.switch label input[type=checkbox]:checked+.lever:before,
.switch label input[type=checkbox]:checked+.lever:after {
    left: 13px;
}

.switch label .lever {
    margin: 0;
    height: 24px;
    display: flex;
    align-items: center;
}

.switch label input[type=checkbox]:checked+.lever {
    background-color: #1a8917;
}

.switch label input[type=checkbox]:checked+.lever:after {
    background-color: var(--cl-white);
}